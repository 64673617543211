<template>
  <div v-if="dialogConfig && dataConfig">

    <v-dialog :value="dialogConfig" max-width="50%" persistent transition="dialog-bottom-transition">

      <v-card>
        <v-toolbar>
          <v-btn icon @click="closeDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="text-uppercase">
            {{ $t("title.user_config_betting") }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-toolbar-items>
            <v-btn :disabled="loading" text @click="validate">
              {{ $t("button.save") }}
            </v-btn>
          </v-toolbar-items> -->
        </v-toolbar>
        <Loading :visible="loading" />
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-tabs background-color="blue-grey lighten-5 accent-4" center-active>
                <v-tab v-for="(index, index1) in dataConfig" :key="index1"  @click="getCurrentIdTab(index.channel_type)">
                  <span v-if=" index.channel_type == 1">CO1</span>
                  <span v-if=" index.channel_type == 2">CO2</span>
                  <span v-if=" index.channel_type == 3">CO3</span>
                  <span v-if=" index.channel_type == 4">CO4</span>
                  <span v-if=" index.channel_type == 5">KL1</span>
                  <span v-if=" index.channel_type == 6">BA1</span>
                  <span v-if=" index.channel_type == 7">BA2</span>
                  <span v-if=" index.channel_type == 8">TD1</span>
                  <span v-if=" index.channel_type == 9">TD2</span>
                  <span v-if=" index.channel_type == 10">LO1</span>
                  <span v-if=" index.channel_type == 11">YU1</span>
                  <span v-if=" index.channel_type == 12">AP1</span>
                </v-tab>
                <v-tab-item v-for="(indexOk, index2) in dataConfig" :key="index2">
                  <!-- <span class="text">កំណត់ឈ្នះក្នុង១ថ្ងៃធំបំផុត</span>
                  <span class="pl-4 limit-text">
                    {{ currencyFormatKh(indexOk.total_bet_win_a_day) }}
                  </span>
                  <vuetify-money class="mt-5" v-model="indexOk.total_bet_win_a_day" v-bind:options="options"
                    type="number"
                    :rules="[rules.conf_limit_win_a_day]"
                    />

                  <span class="text"> កំណត់ភ្នាល់ក្នុង១ដៃធំបំផុត </span>
                  <span class="pl-4 limit-text">
                    {{ currencyFormatKh(indexOk.total_bet_a_fight) }}</span>
                  <vuetify-money class="mt-5" v-bind:options="options" 
                  :rules="[rules.conf_limit_in_a_fight]"
                  v-model="indexOk.total_bet_a_fight"
                    type="number" /> -->

                  <span class="text"> ប្រាក់ភ្នាល់តូចបំផុតក្នុង១ប៉ុង </span>
                  <span class="pl-4 limit-text">
                    {{
                        indexOk.currency_type == 1
                          ? 1
                          : indexOk.currency_type == 2
                            ? 5000
                            : indexOk.currency_type == 3
                              ? 50
                              : "loading.."
                    }}</span>
                  <vuetify-money class="mt-5" v-bind:options="options" v-model="indexOk.amount_min" 
                  :rules="[rules.conf_amount_min]"
                  type="number" />

                  <span class="text"> ប្រាក់ភ្នាល់ធំបំផុតក្នុង១ប៉ុង</span>
                  <span class="pl-4 limit-text">
                    {{ currencyFormatKh(passLimitConfig.amount_max) }}</span>
                  <vuetify-money class="mt-5" v-bind:options="options" v-model="indexOk.amount_max" 
                  :rules="[rules.conf_amount_max]"
                  type="number" />

                  <span class="text">កំណត់ចំនួនប៉ុងចាក់បាន</span>
                  <vuetify-money class="mt-5" v-model="indexOk.betting_frequency" v-bind:options="options"
                    type="number"
                    :rules="[rules.betting_frequency]"
                    />
                  <div v-if="indexOk.channel_type == 1 || indexOk.channel_type == 2 || indexOk.channel_type == 3 ||indexOk.channel_type == 4">
                    <span class="text"> Select Operation</span>
                    <v-col col="12" v-if="indexOk.channel_type == 1">
                      <v-combobox v-if="itemChannelu1.length > 0" v-model="selectedChannelu1[0]" :items="itemChannelu1"
                        label="CO1" item-text="name" item-value="item" chips hide-details="auto" style="width: 100%">
                      </v-combobox>
                      <v-spacer></v-spacer>
                    </v-col>
                    <v-col col="12" v-if="indexOk.channel_type == 2">
                      <v-combobox v-if="itemChannelu2.length > 0" v-model="selectedChannelu2[0]" :items="itemChannelu2"
                        label="CO2" item-text="name" item-value="item" chips hide-details="auto" style="width: 100%">
                      </v-combobox>
                    </v-col>
                    <v-col col="12" v-if="indexOk.channel_type == 3">
                      <v-combobox v-if="itemChannelu3.length > 0" v-model="selectedChannelu3[0]" :items="itemChannelu3"
                        label="CO3" item-text="name" item-value="item" chips hide-details="auto" style="width: 100%">
                      </v-combobox>
                    </v-col>
                    <v-col col="12" v-if="indexOk.channel_type == 4">
                      <v-combobox v-if="itemChannelu4.length > 0" v-model="selectedChannelu4[0]" :items="itemChannelu4"
                        label="CO4" item-text="name" item-value="item" chips hide-details="auto" style="width: 100%">
                      </v-combobox>
                    </v-col>
                  </div>
                  <div class="col-12" style="text-align: end;">
                    <v-btn
                      color="primary"
                      :disabled="loading"
                      @click="validate(indexOk.channel_type)"
                    >
                    {{ $t("button.save") }}
                    </v-btn>
                  </div>
              </v-tab-item>
            </v-tabs>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialogConfig: {
      type: Boolean,
      default: false,
    },
    passPlayer: {
      type: Object,
      default: Object,
    },
    darkmode: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
     rules: {
      type: Object,
      default: function () {
        return {
          // conf_amount_min: (v) => {
          //   let min;
          //   this.passPlayer.currency_type == 1
          //     ? (min = 1)
          //     : this.passPlayer.currency_type == 2
          //     ? (min = 5000)
          //     : this.passPlayer.currency_type == 3
          //     ? (min = 50)
          //     : "";
          //   if (
          //     !isNaN(parseInt(v.replace(/[,]/g, ''))) &&
          //     parseInt(v.replace(/[,]/g, '')) >= min &&
          //     parseInt(v.replace(/[,]/g, '')) >= this.passLimitConfig.amount_min &&
          //     parseInt(v.replace(/[,]/g, '')) <= this.passLimitConfig.amount_max
          //   ) return true;
          //   return `Number has to be between ${this.currencyFormatKh(this.passLimitConfig.amount_min)} and ${this.currencyFormatKh(this.passLimitConfig.amount_max)}`;
          // },
          // conf_amount_max: (v) => {
          //   let min;
          //   this.passPlayer.currency_type == 1
          //     ? (min = 1)
          //     : this.passPlayer.currency_type == 2
          //     ? (min = 5000)
          //     : this.passPlayer.currency_type == 3
          //     ? (min = 50)
          //     : "";
          //   if (
          //     !isNaN(parseInt(v.replace(/[,]/g, ''))) &&
          //     parseInt(v.replace(/[,]/g, '')) >= min &&
          //     parseInt(v.replace(/[,]/g, '')) <= this.passLimitConfig.amount_max
          //   )
          //     return true;
          //   return `Number has to be between ${this.currencyFormatKh(min)} and ${this.currencyFormatKh(this.passLimitConfig.amount_max)}`;
          // },
          // conf_limit_in_a_fight: (v) => {
          //   let min;
          //   this.passPlayer.currency_type == 1
          //     ? (min = 1)
          //     : this.passPlayer.currency_type == 2
          //     ? (min = 5000)
          //     : this.passPlayer.currency_type == 3
          //     ? (min = 50)
          //     : "";
          //   if (
          //     !isNaN(parseInt(v.replace(/[,]/g, ''))) &&
          //     parseInt(v.replace(/[,]/g, '')) >= min &&
          //     parseInt(v.replace(/[,]/g, '')) <= this.passLimitConfig.total_bet_a_fight
          //   )
          //     return true;
          //   return `Number has to be between ${this.currencyFormatKh(min)} and ${this.currencyFormatKh(this.passLimitConfig.total_bet_a_fight)}`;
          // },
          // conf_limit_win_a_day: (v) => {
          //   let min;
          //   this.passPlayer.currency_type == 1
          //     ? (min = 1)
          //     : this.passPlayer.currency_type == 2
          //     ? (min = 5000)
          //     : this.passPlayer.currency_type == 3
          //     ? (min = 50)
          //     : "";
          //   if (
          //     !isNaN(parseInt(v.replace(/[,]/g, ''))) &&
          //     parseInt(v.replace(/[,]/g, '')) >= min &&
          //     parseInt(v.replace(/[,]/g, '')) <= this.passLimitConfig.total_bet_win_a_day
          //   )
          //     return true;
          //   return `Number has to be between ${this.currencyFormatKh(min)} and ${this.currencyFormatKh(this.passLimitConfig.total_bet_win_a_day)}`;
          // },
          // betting_frequency:(v) =>{
          //     !isNaN(parseInt(v)) &&
          //     parseInt(v) > 0
          // },
        };
      },
    },
    isValidate: {
      type: Boolean,
      default: true,
    },
    // dataConfig: {
    //   type: Object,
    //   default: Object,
    // },
    dataConfig:[],
    passLimitConfig:[],
    // passLimitConfig: {
    //   type: Object,
    //   default: Object,
    // },
  },
  watch: {
    dataConfig: function (newV) {
      this.correctSelectChanel(newV);
    },
  },
  computed: {
    valid: {
      // getter
      get: function () {
        return this.isValidate;
      },
      // setter
      set: function (newValue) {
        this.$emit("update:isValidate", newValue);
      },
    },
    itemChannelu1() {
     let checkOption = [{
        channel_type: 1,
        id: "11",
        name: "Default",
        vip_option: 1
      },
        {
        channel_type: 1,
        id: "12",
        name: "Vip 1",
        vip_option: 2
      },
      {
        channel_type: 1,
        id: "13",
        name: "Vip 2",
        vip_option: 3
      }
      ]
      return checkOption;
    },
    itemChannelu2() {
       let checkOption = [{
        channel_type: 2,
        id: "21",
        name: "Default",
        vip_option: 1
      },
        {
        channel_type: 2,
        id: "22",
        name: "Vip 1",
        vip_option: 2
      },
      {
        channel_type: 2,
        id: "23",
        name: "Vip 2",
        vip_option: 3
      }
      ]
      return checkOption;
      
    },
    itemChannelu3() {
      let checkOption = [{
        channel_type: 3,
        id: "31",
        name: "Default",
        vip_option: 1
      },
        {
        channel_type: 3,
        id: "32",
        name: "Vip 1",
        vip_option: 2
      },
      {
        channel_type: 3,
        id: "33",
        name: "Vip 2",
        vip_option: 3
      }
      ]
      return checkOption;
    },
    itemChannelu4() {
      let checkOption = [{
        channel_type: 4,
        id: "41",
        name: "Default",
        vip_option: 1
      },
        {
        channel_type: 4,
        id: "42",
        name: "Vip 1",
        vip_option: 2
      },
      {
        channel_type: 4,
        id: "43",
        name: "Vip 2",
        vip_option: 3
      }
      ]
      return checkOption;
    },
    
  },
  data: () => {
    return {
      showPassword: false,
      selectionChannelu1: [],
      selectedChannelu1: [],
      selectionChannelu2: [],
      selectedChannelu2: [],
      selectionChannelu3: [],
      selectedChannelu3: [],
      selectionChannelu4: [],
      selectedChannelu4: [],
      selectionChannel: [],
      options: {
        locale: "integer",
        length: 21,
        precision: 0
      },
    };
  },
  methods: {
    validate(channel_type) {
      // console.log(this.$refs.form.validate());
      if (this.$refs.form.validate()) {
        for(let i in this.dataConfig){
          if(this.dataConfig[i].channel_type == 1){
            this.dataConfig[i].channel_allow = this.selectedChannelu1;
          }else if(this.dataConfig[i].channel_type == 2){
            this.dataConfig[i].channel_allow = this.selectedChannelu2;
          }else if(this.dataConfig[i].channel_type == 3){
            this.dataConfig[i].channel_allow = this.selectedChannelu3;
          }else if(this.dataConfig[i].channel_type == 4){
            this.dataConfig[i].channel_allow = this.selectedChannelu4;
          }
        }
        this.$emit("submit",channel_type);
        // this.selectionChannel = [];
        // this.selectionChannelu1 = [];
        // this.selectedChannelu1 = [];
        // this.selectionChannelu2 = [];
        // this.selectedChannelu2 = [];
        // this.selectionChannelu3 = [];
        // this.selectedChannelu3 = [];
        // this.selectionChannelu4 = [];
        // this.selectedChannelu4 = [];
        // this.dataConfig.channel_allow = [];
      }
    },
    currencyFormatKh(num) {
      num = parseFloat(num);
      // return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
      return parseInt(num).toLocaleString("en");
    },
    closeDialog(){
       this.selectionChannel = [];
        this.selectionChannelu1 = [];
        this.selectedChannelu1 = [];
        this.selectionChannelu2 = [];
        this.selectedChannelu2 = [];
        this.selectionChannelu3 = [];
        this.selectedChannelu3 = [];
        this.selectionChannelu4 = [];
        this.selectedChannelu4 = [];
      this.$emit('onCloseDialog')
    },
    getCurrentIdTab(id) {
      this.$emit("changeTab",id);
    },
    correctSelectChanel(item) {
      for (let i in item) {
        if (item[i].channel_type == 1) {
          for(let j in item[i].channel_allow){
            this.selectionChannelu1.push({
            id:
            item[i].channel_allow[j].channel_type +
              "" +
              item[i].channel_allow[j].vip_option,
            name:
            item[i].channel_allow[j].vip_option == 1
                ? "Default"
                : item[i].channel_allow[j].vip_option == 2
                  ? "Vip 1"
                  : item[i].channel_allow[j].vip_option == 3
                    ? "Vip 2"
                    : "",
            vip_option: item[i].channel_allow[j].vip_option,
            channel_type: item[i].channel_allow[j].channel_type,
          });
          }
          
        }
        if (item[i].channel_type == 2) {
          for(let j in item[i].channel_allow){
            this.selectionChannelu2.push({
            id:
            item[i].channel_allow[j].channel_type +
              "" +
              item[i].channel_allow[j].vip_option,
            name:
            item[i].channel_allow[j].vip_option == 1
                ? "Default"
                : item[i].channel_allow[j].vip_option == 2
                  ? "Vip 1"
                  : item[i].channel_allow[j].vip_option == 3
                    ? "Vip 2"
                    : "",
            vip_option: item[i].channel_allow[j].vip_option,
            channel_type: item[i].channel_allow[j].channel_type,
          });
          }
        }
        if (item[i].channel_type == 3) {
          for(let j in item[i].channel_allow){
            this.selectionChannelu3.push({
            id:
            item[i].channel_allow[j].channel_type +
              "" +
              item[i].channel_allow[j].vip_option,
            name:
            item[i].channel_allow[j].vip_option == 1
                ? "Default"
                : item[i].channel_allow[j].vip_option == 2
                  ? "Vip 1"
                  : item[i].channel_allow[j].vip_option == 3
                    ? "Vip 2"
                    : "",
            vip_option: item[i].channel_allow[j].vip_option,
            channel_type: item[i].channel_allow[j].channel_type,
          });
          }
        }
        if (item[i].channel_type == 4) {
          for(let j in item[i].channel_allow){
            this.selectionChannelu4.push({
            id:
            item[i].channel_allow[j].channel_type +
              "" +
              item[i].channel_allow[j].vip_option,
            name:
            item[i].channel_allow[j].vip_option == 1
                ? "Default"
                : item[i].channel_allow[j].vip_option == 2
                  ? "Vip 1"
                  : item[i].channel_allow[j].vip_option == 3
                    ? "Vip 2"
                    : "",
            vip_option: item[i].channel_allow[j].vip_option,
            channel_type: item[i].channel_allow[j].channel_type,
          });
          }
        }
      }
      this.selectedChannelu1 = this.selectionChannelu1;
      this.selectedChannelu2 = this.selectionChannelu2;
      this.selectedChannelu3 = this.selectionChannelu3;
      this.selectedChannelu4 = this.selectionChannelu4;
    },
  },
  
};
</script>
<style scoped>
.limit-text {
  color: #ef9a9a;
  font-weight: bold;
  font-size: 20px;
}

.text {
  font-weight: bold;
  font-size: 20px;
}
</style>
